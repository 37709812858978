import { Container, Grid } from '@mui/material';
import React from 'react';
import { servicesData } from '../../_mock/data';
import box from './../../assets/box2.png';
import './Services.scss';

const Services = () => {
    return (
        <div className='services'>
            <img className='service_box_image' src={box} alt="" />
            <Container>
                <h1 className='title'>Commonly Requested Services</h1>
                <div className='d_f j_c_c'>
                    <div className="line"></div>
                </div>

                <Grid container spacing={2} className="j_c_c">
                    {
                        servicesData?.map((s) => (
                            <Grid key={s?.id} item xs={12} md={6} lg={4}>
                                <div style={{background: s?.bgColor}} className="servic_card">
                                    <img src={s?.image} alt="" />
                                    <h1>{s?.title}</h1>
                                    <p>{s?.desc}</p>
                                </div>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </div>
    );
};

export default Services;