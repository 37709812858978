import { Container, Grid } from '@mui/material';
import React from 'react';
import { chooseusData } from '../../_mock/data';
import './WhyChooseUs.scss';

const WhyChooseUs = () => {
    return (
        <>
            <div className="why_choose_us">
                <Container>
                    <div className="d_f j_c_c">
                        <h1>Why choose us</h1>
                    </div>

                    <Grid container spacing={4} className="j_c_c">
                        {
                            chooseusData?.map((c) => (
                                <Grid item xs={6} key={c?.id} sm={6} md={4} lg={2.4}>
                                    <div className="choose_card">
                                        <img src={c?.image} alt="" />
                                        <button>{c?.btnText}</button>
                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Container>
            </div>

            <div className="offer_section">
            <Container>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <div className="offered_card">
                            <h1>Services Offered</h1>
                            <p>We have an extensive range of services and more and more getting added to the list daily, book in a free consultation and find out what services fits you best.</p>
                            <button>Click To View Our Services</button>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            </div>
        </>
    );
};

export default WhyChooseUs;