import React from 'react';
import Social from '../social/Social';
import logoImg from './../../assets/logos/logo.png';
import './Navbar.scss'

const Navbar = () => {
    return (
        <div className='navbar'>
            <div className='nav_logo'>
                <img className='main_logo' src={logoImg} alt="" />
            </div>
            <div className='nav_social'>
            <Social />
            </div>
        </div>
    );
};

export default Navbar;