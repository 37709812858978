import { Grid } from '@mui/material';
import React from 'react';
import dImg2 from './../../assets/dollarImage.png';
import dImg from './../../assets/dollarImage2.png';
import './DidYouKnow.scss';

const DidYouKnow = () => {
    return (
        <Grid container spacing={2} sx={{ mt: '4rem', mb: '4rem' }}>
            <Grid item xs={12}  md={6} lg={6} className='laptop_image'>
                <div>
                    <img src={dImg2} alt="" />
                </div>
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
                <div className="did_you_know_text">
                    <h1>Did you know?</h1>
                    <div className="line_p_div">
                    <div className="line"></div>
                    </div>
                    <p>
                        We pride ourselves on being more than just a <b>Defi-As-A-Service</b> firm. We also offer a fully renounced community token on the <b>Binance Smart Chain</b> called <b>$OSBS.</b>  30% of our monthly profits are used to buy back and burn <b>$OSBS</b> tokens, making it a great passive income for investors. In addition, <b>$OSBS </b> holders will receive exclusive benefits and discounts when ordering our services.
                    </p>
                    <span>
                        5% BUY/SELL TAXES
                    </span>

                    <div className="btn_group">
                        <button className="black_btn">BUY $OSBS</button>
                        <button className="black_btn">CHART</button>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
                <div className="did_you_know_img">
                    <img src={dImg} className="w_100 " alt="" style={{ height: '75vh' }} />
                </div>
            </Grid>
        </Grid>
    );
};

export default DidYouKnow;