import { Container, Grid } from '@mui/material';
import React from 'react';
import boxImg from './../../assets/boxImage.png';
import box from './../../assets/box.png';
import './WhoAreYou.scss';

const WhoAreYou = () => {
    return (
        <Container sx={{marginTop: '4vmax', marginBottom: '4vmax'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <div className='who_are_you_text'>
                        <h1>Who we are?</h1>
                        <p>
                        One Stop Blockshop is a full-service blockchain development studio. We offer ideation, development, deployment, and maintenance services to help our clients get their projects off the ground quickly and affordably. Our team of experienced developers is committed to providing the highest quality service at the most competitive prices, and we guarantee your satisfaction with our work. Contact us today to learn more about how we can help you bring your project to life!
                        </p>
                        <div className="contact_bt_div">
                        <button className="black_btn">Contact Us</button>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <div  className='who_are_you_img'>
                        <img className='w_100' src={boxImg} alt="" />
                        <img className='box_image' src={box} alt="" />
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default WhoAreYou;