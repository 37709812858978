import { Container } from '@mui/material';
import React from 'react';
import Navbar from '../navbar/Navbar';
import Social from '../social/Social';
import './Header.scss';

const Header = () => {
    return (
        <div className='header'>
            <Navbar />

            <Container>
                <div className='header_text'>
                    <h2>The One Stop Solution for All Your <br /> Blockchain Needs</h2>
                    <p>
                        PINKSALE Partner. Get Safu, Audit, and KYC badges for your <br /> Presale/ Fairlaunch/ Subscription.
                    </p>
                    <p> Making <b>Defi-As-A-Service</b>  simple and affordable for all <br /> walks of life.</p>
                </div>

                <div className='btn_group'>
                    <button className='primary_button'>Play Video</button>
                    <button className='primary_button'>Contact Telegram</button>
                </div>

                <div className="header_social">
                    <Social />
                </div>
            </Container>
        </div>
    );
};

export default Header;