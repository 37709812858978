import { Grid } from '@mui/material';
import React from 'react';
import Social from '../social/Social';
import footerImg from './../../assets/footerImg.png';
import logoImg from './../../assets/logos/logo.png';
import payment from './../../assets/payment.png';
import './Footer.scss';

const Footer = () => {
    return (
        <div className="footer">
            <Grid container>
                <Grid item xs={0} md={6} lg={6} className="footer_left">
                    <div>
                        <img src={footerImg} className="  footerImg"  alt="" />
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <div className="footer_right">

                        <img src={logoImg} alt="" className="logoImg" />
                        <br />
                        <Social />
                        <p>info@onestopblockshop.io</p>
                        <button>Book In A Free Consultation</button>
                        <br />
                        <p>We also accept</p>
                        <div className="paymentMehtod">
                            <img src={payment} alt="" />
                        </div>
                        <p className="copy_p">One Stop Blockshop. All Rights Reserved 2022</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Footer;