import React from 'react';
import ContactUs from '../components/contactus/ContactUs';
import DidYouKnow from '../components/didYouKnow/DidYouKnow';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import Services from '../components/services/Services';
import WhoAreYou from '../components/whoareyou/WhoAreYou';
import WhyChooseUs from '../components/whychooseus/WhyChooseUs';

const Home = () => {
    return (
        <div>
            <Header />
            {/* clean */}
            <WhoAreYou /> 
                {/* clean */}
            <Services />
                {/* clean */}
            <WhyChooseUs />
             {/* clean */}
            <DidYouKnow />
             {/* clean */}
            <ContactUs />
               {/* clean */}
            <Footer />
        </div>
    );
};

export default Home;