import { Container, Grid } from '@mui/material';
import React from 'react';
import './ContactUs.scss';

const ContactUs = () => {
    return (
        <div className="contact_us">
            <Container>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={6}>
                        <div className="contact_text">
                            <h1>Contact Us</h1>
                            <p>
                                Can't find what you're looking for? Send us an email and we will get back to you request within 24 hours.
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <div>
                            <form action="" className="contact_form">
                                <div className="input_div">
                                    <p>Name</p>
                                    <input type="text" />
                                </div>
                                <div className="input_div">
                                    <p>Email</p>
                                    <input type="text" />
                                </div>
                                <div className="input_div">
                                    <p>Message</p> 
                                    <textarea name="" placeholder='Type your message' id="" cols="30" rows="8"></textarea>
                                </div>
                                <button>Submit</button>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default ContactUs;