import sImg1 from './../assets/service1.png';
import sImg2 from './../assets/service2.png';
import sImg3 from './../assets/service3.png';

// why_choose_us images
import cImg1 from './../assets/cImg1.png';
import cImg2 from './../assets/cImg2.png';
import cImg3 from './../assets/cImg3.png';
import cImg4 from './../assets/cImg4.png';
import cImg5 from './../assets/cImg5.png';


export const servicesData = [
    {
        id: 1,
        title: 'Whitepaper Writing',
        desc: 'A well-written whitepaper can help to build trust and credibility for a project, which is essential in the highly competitive world of Crypto and NFTs.',
        image: sImg1,
        bgColor: '#F5F5F5'
    },
    {
        id: 2,
        title: 'Smart Contracts',
        desc: 'Smart contracts are immutable and once they are deployed, they cannot be changed. Let us deal with making your custom smart , staking & presale, and NFT contracts.',
        image: sImg2,
        bgColor: '#EDF9FE'
    },

    {
        id: 3,
        title: 'KYC & Audit',
        desc: 'Bring trust to your project with a smart contract audit and a Know Your Customer Verification at a faster pace and affordable prices .',
        image: sImg3,
        bgColor: '#F5F8FB'
    },
]


// why_choose_us
export const chooseusData = [
    {
        id: 1,
        image: cImg1,
        btnText: 'Reasonable Priceing'
    },
    {
        id: 2,
        image: cImg2,
        btnText: 'Qualified Team'
    },
    {
        id: 3,
        image: cImg3,
        btnText: 'Fast Turnaround'
    },
    {
        id: 4,
        image: cImg4,
        btnText: 'Secured Payments'
    },
    {
        id: 5,
        image: cImg5,
        btnText: 'Dedicated Support'
    },
]