import { Fragment } from 'react'; 
import Home from './pages/Home';
import './classes.scss';

function App() {
  return (
    <Fragment>
      <Home />
    </Fragment>
  );
}

export default App;
