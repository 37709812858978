import React from 'react';
import mail from './../../assets/logos/mail.png';
import twiter from './../../assets/logos/twitter.png';
import instagra from './../../assets/logos/Instagram.png';
import github from './../../assets/logos/Github.png';
import telegram from './../../assets/logos/Telegram.png';
import './Social.scss';

const Social = () => {
    return (
        <div>
            <ul className='social d_f'>
                <li>
                    <a href="#/">
                        <img src={mail} alt={mail} />
                    </a>
                </li>
                <li>
                    <a href="#/">
                        <img src={twiter} alt={twiter} />
                    </a>
                </li>
                <li>
                    <a href="#/">
                        <img src={instagra} alt={instagra} />
                    </a>
                </li>
                <li>
                    <a href="#/">
                        <img src={github} alt={github} />
                    </a>
                </li>
                <li>
                    <a href="#/">
                        <img src={telegram} alt={telegram} />
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Social;